var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Kif8G73_z-ZwCnMoHLJxr"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (
  process.env.NEXT_PUBLIC_ENV === 'dev' ||
  process.env.NEXT_PUBLIC_ENV === 'uat' ||
  process.env.NEXT_PUBLIC_ENV === 'production'
) {
  Sentry.init({
    environment: process.env.NEXT_PUBLIC_ENV || process.env.NODE_ENV,
    dsn:
      SENTRY_DSN ||
      'https://94d225c58d414c5f840dcc7a0087165d@o4504909547044864.ingest.sentry.io/4504909619068928',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.1,
    ignoreErrors: [
      // Error when Outlook scans a link
      // https://github.com/getsentry/sentry-javascript/issues/3440
      'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
    ],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });

  // Pass through compatible API version tag
  Sentry.setTag(
    'compatible_api_version',
    process.env.NEXT_PUBLIC_COMPATIBLE_API_VERSION || ''
  );
}
